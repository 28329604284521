import React, { Component } from 'react';

import { useStaticQuery, graphql } from 'gatsby';

import GoogleMapReact from 'google-map-react';




const MapMarkerIcon = () =>
{
    const data = useStaticQuery(graphql`
        query {
            mapmarker: file(relativePath: { eq: "mapmarker.png" }) {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)

    return <img draggable="false" src={data.mapmarker.childImageSharp.fluid.src} style={{display: 'block'}} alt="" width="73" height="92" />
}

const Marker = props =>
{
    /* useEffect(() => {
        console.log('Marker:','render');
        return () => {
            console.log('Marker:','unmount 2');
        };
    }, []); */

    return (
        <div className="marker">
            <MapMarkerIcon />
        </div>
    )
}

class Mapa extends Component
{
    render()
    {
        //console.log('Mapa:','render');

        return (
            <div className="bloco__contato__mapa" id="mapa">
                <h2 className="bloco__contato__title">Localização</h2>
                <div className="googlemaps">
                    <GoogleMapReact 
                      bootstrapURLKeys={{ key: 'AIzaSyA6BDqx6KepnI6ts8cC7boiq4SeTqfLfWA' }} 
                      defaultCenter={{lat: -21.2170906, lng: -50.4429815}} 
                      defaultZoom={17}>
                        <Marker lat={-21.2170906} lng={-50.4429815} />
                    </GoogleMapReact>
                </div>
            </div>
        );
    }

    componentDidMount()
    {
        //console.log('Mapa:','mount');
    }

    componentDidUpdate()
    {
        //console.log('Mapa:','update');
    }

    componentWillUnmount()
    {
        //console.log('Mapa:','unmount');
    }
}

export default Mapa;