import React, { Component } from 'react';

import Swal from 'sweetalert2';

import withReactContent from 'sweetalert2-react-content';




const MySwal = withReactContent(Swal);

class Formulario extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            erroRazao: '',
            erroCnpj: '',
            erroNome: '',
            erroEmail: '',
            erroTelefone: '',
            erroAssunto: '',
            erroMensagem: '',

            telefone: '',
            cnpj: '',
            formsend: false,
        };

        this.inputRazao = React.createRef();
        this.inputCnpj = React.createRef();
        this.inputNome = React.createRef();
        this.inputEmail = React.createRef();
        this.inputAssunto = React.createRef();
        this.inputMensagem = React.createRef();

        this.checkRazao = this.checkRazao.bind(this);
        this.checkCnpj = this.checkCnpj.bind(this);
        this.checkNome = this.checkNome.bind(this);
        this.checkEmail = this.checkEmail.bind(this);
        this.checkTelefone = this.checkTelefone.bind(this);
        this.checkAssunto = this.checkAssunto.bind(this);
        this.checkMensagem = this.checkMensagem.bind(this);

        this.maskPhone = this.maskPhone.bind(this);
        this.maskCnpj = this.maskCnpj.bind(this);
        this.isCNPJ = this.isCNPJ.bind(this);

        this.cnpjHandleChange = this.cnpjHandleChange.bind(this);
        this.telefoneHandleChange = this.telefoneHandleChange.bind(this);

        this.formSend = this.formSend.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    maskPhone = v =>
    {
        var len = 0;

        //Remove tudo o que não é dígito
        v = v.replace(/\D/g, "");
        v = v.replace(/-/g, "");
        v = v.replace(/\./g, "");

        len = v.length;

        //Coloca parênteses em volta dos dois primeiros dígitos
        v = v.replace(/^(\d\d)(\d)/g, "($1) $2");

        //Coloca hífen entre o quarto e o quinto dígitos
        if (len > 10) {
            v = v.replace(/(\d{5})(\d)/, "$1-$2").slice(0, 15);
        } else {
            v = v.replace(/(\d{4})(\d)/, "$1-$2").slice(0, 14);
        }

        return v;
    }

    // 99.999.999/9999-99
    maskCnpj = v =>
    {
        v = v.replace(/\D/g, "");
        //v = v.replace(/\-/g, "");
        //v = v.replace(/\./g, "");
        v = v.slice(0, 14);

        v = v.replace(/(\d{2})(\d)/, "$1.$2");
        v = v.replace(/(\d{3})(\d)/, "$1.$2");
        v = v.replace(/(\d{3})(\d{1,4})/, "$1/$2");
        v = v.replace(/(\d{4})(\d{1,2})/, "$1-$2");

        return v;//.slice(0, 18);
    }

    isCNPJ = cnpj =>
    {
        let ncnpj = cnpj.replace(/[^\d]+/g,'');
        // Elimina CNPJs invalidos conhecidos
        if (ncnpj.length !== 14 ||
            ncnpj === "00000000000000" || 
            ncnpj === "11111111111111" || 
            ncnpj === "22222222222222" || 
            ncnpj === "33333333333333" || 
            ncnpj === "44444444444444" || 
            ncnpj === "55555555555555" || 
            ncnpj === "66666666666666" || 
            ncnpj === "77777777777777" || 
            ncnpj === "88888888888888" || 
            ncnpj === "99999999999999")
            return false;

        //console.log('isCNPJ:',cnpj);
        //console.log('isCNPJ:',ncnpj);

        // Valida DVs
        var tamanho = ncnpj.length - 2;
        var numeros = ncnpj.substring(0,tamanho);
        var digitos = ncnpj.substring(tamanho);
        var soma = 0;
        var pos = tamanho - 7;
        for (var i = tamanho; i >= 1; i--)
        {
            soma += numeros.charAt(tamanho - i) * pos--;
            if (pos < 2)
                pos = 9;
        }
        var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        var chatAtZero = parseInt(digitos.charAt(0));
        if (resultado !== chatAtZero)
            return false;
            
        tamanho = tamanho + 1;
        numeros = ncnpj.substring(0,tamanho);
        soma = 0;
        pos = tamanho - 7;
        for (i = tamanho; i >= 1; i--)
        {
            soma += numeros.charAt(tamanho - i) * pos--;
            if (pos < 2)
                pos = 9;
        }
        resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        var chatAtOne = parseInt(digitos.charAt(1));
        if (resultado !== chatAtOne)
            return false;
        return true;
    }

    //

    checkRazao(e)
    {
        this.setState({
            erroRazao: this.inputRazao.current.value.trim().length < 3 || this.inputRazao.current.value.trim().length > 128 ? 'Razão social muito curta' : ''
        });
    }

    checkCnpj(e)
    {
        this.setState({
            erroCnpj: (
                !/^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/.test(this.state.cnpj) ? 'CNPJ incompleto' : (
                                                                                                    !this.isCNPJ(this.state.cnpj) ? 'CNPJ inválido' : ''
                                                                                                  )
            )
        });
    }

    checkNome(e)
    {
        this.setState({
            erroNome: !/\S+(?:\s+\S+)+/.test(this.inputNome.current.value.trim()) ? 'Nome incompleto' : ''
        });
    }

    checkEmail(e)
    {
        const rgEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        this.setState({
            erroEmail: !rgEmail.test(this.inputEmail.current.value.trim()) ? 'E-mail incorreto' : ''
        });
    }

    checkTelefone(e)
    {
        // e.target.value
        this.setState({
            erroTelefone: !/^\([0-9]{2}\) [0-9]{4,5}-[0-9]{4}$/.test(this.state.telefone) ? 'Telefone incompleto' : ''
        });
    }

    checkAssunto(e)
    {
        this.setState({
            erroAssunto: this.inputAssunto.current.value.trim().length < 3 ? 'Assunto muito curto' : ''
        });
    }

    checkMensagem(e)
    {
        this.setState({
            erroMensagem: this.inputMensagem.current.value.trim().length < 8 ? 'Mensagem muito curta' : ''
        });
    }

    //

    cnpjHandleChange(e)
    {
        this.setState({
            cnpj: this.maskCnpj(e.target.value)
        });
    }

    telefoneHandleChange(e)
    {
        this.setState({
            telefone: this.maskPhone(e.target.value)
        });
    }

    async formSend(obj)
    {
        const { razao, cnpj, nome, email, telefone, assunto, mensagem } = obj;

        /* console.log(
            'Razão: ' + razao +
            '\nCnpj: ' + cnpj +
            '\nNome: ' + nome +
            '\nE-mail: ' + email +
            '\nTelefone: ' + telefone +
            '\nAssunto: ' + assunto +
            '\nNensagem: ' + mensagem
        ); */

        //

        var formdata = new FormData();
        formdata.append("action", "contato");
        formdata.append("razao", razao);
        formdata.append("cnpj", cnpj);
        formdata.append("nome", nome);
        formdata.append("email", email);
        formdata.append("telefone", telefone);
        formdata.append("assunto", assunto);
        formdata.append("mensagem", mensagem);

        var requestOptions = {
            method: 'POST',
            redirect: 'follow',
            mode: "cors",
            body: formdata,
        };

        //let responseData = await fetch("https://aeradmin.agencianeoplan.com.br/wp-admin/admin-ajax.php", requestOptions);
        //let jsonData = await responseData.json();

        //await fetch("https://aeradmin.agencianeoplan.com.br/wp-admin/admin-ajax.php", requestOptions)
        await fetch("https://cms.arinteligenciatributaria.com.br/wp-admin/admin-ajax.php", requestOptions)
        .then((response) => {
            if (response.status >= 400 && response.status < 600) {
              throw new Error("Bad response from server");
            }
            return response.text();
        })
        .then((text) =>
        {
            this.setState({
                formsend: false
            });

            //console.log('text:',text);

            const obj = JSON.parse(text);
            const { cod, title, msg } = obj;

            MySwal.fire({
                title: typeof title !== "undefined" && <div dangerouslySetInnerHTML={{__html: title}} />,
                html: <p dangerouslySetInnerHTML={{__html: msg}} />,
                icon: cod !== 1 ? 'warning' : 'success'
            });

            if ( cod === 1 )
            {
                this.inputRazao.current.value = '';
                // setstate - cnpj
                this.inputNome.current.value = '';
                this.inputEmail.current.value = '';
                // setstate - telefone
                this.inputAssunto.current.value = '';
                this.inputMensagem.current.value = '';

                this.setState({
                    telefone: '',
                    cnpj: '',
                });
            }
        })
        .catch((error) =>
        {
            //console.log('error:',error);
            this.setState({
                formsend: false
            });

            MySwal.fire({
                html: <p dangerouslySetInnerHTML={{__html: "Desculpe, não foi possível concluir a solicitação. Por favor, entre em contato por outro meio."}} />,
                icon: 'error'
            });
        });
    }

    handleSubmit(e)
    {
        e.preventDefault();

        if (
            this.state.formsend === false
        )
        {
            //const rgEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            const rgEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            const { telefone, cnpj } = this.state;

            const trimrazao = this.inputRazao.current.value.trim();
            const trimnome = this.inputNome.current.value.trim();
            const trimemail = this.inputEmail.current.value.trim();
            const trimassunto = this.inputAssunto.current.value.trim();
            const trimmensagem = this.inputMensagem.current.value.trim();

            if (
                trimrazao.length < 3 ||
                trimrazao.length > 128 ||
                !this.isCNPJ(cnpj) ||
                !/\S+(?:\s+\S+)+/.test(trimnome) ||
                !rgEmail.test(trimemail) ||
                !/^\([0-9]{2}\) [0-9]{4,5}-[0-9]{4}$/.test(telefone) ||
                trimassunto.length < 3 ||
                trimmensagem.length < 8
            )
            {
                this.setState({
                    erroRazao: '',
                    erroCnpj: '',
                    erroNome: '',
                    erroEmail: '',
                    erroTelefone: '',
                    erroAssunto: '',
                    erroMensagem: ''
                }, () =>
                {
                    this.checkRazao();
                    this.checkCnpj();
                    this.checkNome();
                    this.checkEmail();
                    this.checkTelefone();
                    this.checkAssunto();
                    this.checkMensagem();
    
                    MySwal.fire({
                        title: "",//<div dangerouslySetInnerHTML="" />,
                        html: <p dangerouslySetInnerHTML={{__html: 'Por favor, revise os campos e tente novamente'}} />,
                        icon: 'warning'
                    });    
                });

                return false;
            }

            this.setState({
                formsend: true,
            });

            this.formSend({
                razao: trimrazao,
                cnpj,
                nome: trimnome,
                email: trimemail,
                telefone,
                assunto: trimassunto,
                mensagem: trimmensagem
            });
        }
    }

    render()
    {
        //console.log('Formulario:','render');

        return (
            <>
                <div className="bloco__contato__formulario">
                    {this.state.formsend === true && <div className="formmessage">Processando...</div>}
                    {/* <h2 className="bloco__contato__title">Deixe a sua mensagem</h2> */}
                    <h2 className="bloco__contato__title">Preencha os dados abaixo</h2>
                    <p className="bloco__contato__title__text">Faça uma simulação/orçamento conosco e veja se você tem créditos a serem recuperados.</p>
                    <form onSubmit={this.handleSubmit} className={this.state.formsend === true ? `form--formmessage` : ``}>
                        <div className="input__row" data-aos="fade-in" data-aos-duration="1000">
                            <input tabIndex="0" type="text" className={this.state.erroRazao.length !== 0 ? 'input--error' : ''} ref={this.inputRazao} onBlur={this.checkRazao} placeholder="Razão social" aria-label="Razão social" maxLength="128" />
                            <span className={this.state.erroRazao.length === 0 ? 'error' : 'error error--show'}>{this.state.erroRazao}</span>
                        </div>
                        <div className="input__row" data-aos="fade-in" data-aos-duration="1000">
                            <input tabIndex="0" type="text" className={this.state.erroCnpj.length !== 0 ? 'input--error' : ''} value={this.state.cnpj} onBlur={this.checkCnpj} onChange={this.cnpjHandleChange} placeholder="CNPJ" aria-label="CNPJ" maxLength="18" />
                            <span className={this.state.erroCnpj.length === 0 ? 'error' : 'error error--show'}>{this.state.erroCnpj}</span>
                        </div>
                        <div className="input__row" data-aos="fade-in" data-aos-duration="1000">
                            <input tabIndex="0" type="text" className={this.state.erroNome.length !== 0 ? 'input--error' : ''} ref={this.inputNome} onBlur={this.checkNome} placeholder="Nome completo" aria-label="Nome completo" maxLength="128" />
                            <span className={this.state.erroNome.length === 0 ? 'error' : 'error error--show'}>{this.state.erroNome}</span>
                        </div>
                        <div className="input__row" data-aos="fade-in" data-aos-duration="1000">
                            <input tabIndex="0" type="text" className={this.state.erroEmail.length !== 0 ? 'input--error' : ''} ref={this.inputEmail} onBlur={this.checkEmail} placeholder="Seu E-mail" aria-label="Seu E-mail" maxLength="128" />
                            <span className={this.state.erroEmail.length === 0 ? 'error' : 'error error--show'}>{this.state.erroEmail}</span>
                        </div>
                        <div className="input__row" data-aos="fade-in" data-aos-duration="1000">
                            <input tabIndex="0" type="tel" className={this.state.erroTelefone.length !== 0 ? 'input--error' : ''} value={this.state.telefone} onBlur={this.checkTelefone} onChange={this.telefoneHandleChange} placeholder="Seu Telefone" aria-label="Seu Telefone" maxLength="15" />
                            <span className={this.state.erroTelefone.length === 0 ? 'error' : 'error error--show'}>{this.state.erroTelefone}</span>
                        </div>
                        <div className="input__row" data-aos="fade-in" data-aos-duration="1000">
                            <input tabIndex="0" type="text" className={this.state.erroAssunto.length !== 0 ? 'input--error' : ''} ref={this.inputAssunto} onBlur={this.checkAssunto} placeholder="Assunto" aria-label="Assunto" maxLength="128" />
                            <span className={this.state.erroAssunto.length === 0 ? 'error' : 'error error--show'}>{this.state.erroAssunto}</span>
                        </div>
                        <div className="input__row" data-aos="fade-in" data-aos-duration="1000">
                            <textarea tabIndex="0" className={this.state.erroMensagem.length !== 0 ? 'input--error' : ''} ref={this.inputMensagem} onBlur={this.checkMensagem} placeholder="Sua Mensagem" aria-label="Sua Mensagem" maxLength="2048" />
                            <span className={this.state.erroMensagem.length === 0 ? 'error' : 'error error--show'}>{this.state.erroMensagem}</span>
                        </div>
                        <div className="input__row" data-aos="fade-in" data-aos-duration="1000">
                            <input tabIndex="0" type="submit" value="ENVIAR" aria-label="Enviar" />
                        </div>
                    </form>
                </div>
            </>
        );
    }

    componentDidMount()
    {
        //console.log('Formulario:','mount');
    }

    componentDidUpdate()
    {
        //console.log('Formulario:','update');
    }

    componentWillUnmount()
    {
        //console.log('Formulario:','unmount');
    }
}

export default Formulario;